import axios, { AxiosRequestConfig } from 'axios'
import errorResponseHandler from 'utils/errorHandler'
import { ASSET_MANAGER_ROOT, getOrganisationName } from '../env'

export const API_VERSION_ONE = 'v1'

const pincode = btoa(sessionStorage.getItem(atob('pincode')) || '')

const headersInfo: AxiosRequestConfig['headers'] = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
  locale: localStorage.getItem('Locale') || 'en',
  slug: getOrganisationName(),
  token: localStorage.getItem('onboarding_token'),
  ...(pincode
    ? {
        pincode,
      }
    : {}),
}

export const assetManagerAxiosInstance = axios.create({
  baseURL: ASSET_MANAGER_ROOT,
  withCredentials: true,
  headers: headersInfo,
})

assetManagerAxiosInstance.interceptors.request.use((config) => {
  config.params = {
    ...config.params,
    slug: getOrganisationName(),
  }
  return config
})

// ? To prevent Storybook, because we handling dummy API response
assetManagerAxiosInstance.interceptors?.response?.use(undefined, errorResponseHandler)

import { useEffect, useState } from 'react'
import {
  PageLayout,
  SideBarNav,
  HeaderNavigation,
  Button,
  Level1NavItem,
  Level2NavItem,
} from '@jisr-hr/ds'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'utils/hooks'
import clsx from 'classnames'
import {
  currentEmployeeChangeLanguage,
  getCurrentUser,
  logout,
} from 'redux/authUser/actionCreators'
import I18n from 'translations/i18n'
import { getTokenFromDB } from 'firebaseUtils'
import { unRegistrationToken, unsubscribeFirebase } from 'redux/actions/firebaseActions'
import JisrPayWallet from 'components/Finance/JisrPayWallet'
import Announcement from 'components/global/templates/Announcement/Announcement'
import { toggleNotificationsPanel } from 'redux/appNotifications/actionCreators'
import UserSnap from 'components/UserSnap/UserSnap'
import { isEmpty } from 'lodash'
import { mixPanelAnalytics } from 'utils/mixpanel'
import useIsEmployeeOrg from 'hooks/useIsEmployeeOrg'
import { useUsersnapApi } from 'components/UserSnap/UsersnapContext'
import { Statuses } from 'containers/authorised/NewOnboarding/helper'
import { getOrganisationName, CHATBOT_URL } from 'env'
import { queryClient } from 'hooks/useReactQuery'
import JisrNavLogo from 'assets/images/jisr.svg'
import { JisriChatbotLauncher } from 'containers/authorised/JisrAiChatBot'
import {
  CHATBOT_SAVED_MESSAGES,
  CHATBOT_START_TIMESTAMP,
  JISRI_SELECTED_LANGUAGE,
} from 'containers/authorised/JisrAiChatBot/constants'
import { useAnnouncementUnreadExists } from 'apis/OrgManagement/announcementSegrigation/useAnnouncementSeg'
import { useOrgManagement } from 'store/OrgManagement/useOrgManagement'

import useListItems from './useListItems'
import ChangePasswordModal from './ChangePasswordModal'
import HelpCenter from './HelpCenter'
import useMainNavigation from './useMainNavigation'
import AccountSwitcher from './AccountSwitcher'

import TourStepThree from './components/TourStepThree'
import {
  useMappingNavigation,
  useDisabledMappingNavigation,
  useOnlyOnboardingMappingNavigation,
} from './MappingNavigation'
import OnboardingInviteAdmins from './components/OnboardingInviteAdmins'
import OnboardingHeader from './components/OnboardingHeader'
import styles from './onboardingInviteEmployees.module.css'

const SMELogo = 'https://staticfiles.jisr.net/static-files-main/assets/jisr/images/LogoSME.svg'

type NewNavigationProps = {
  children: React.ReactNode
}

const NewNavigation = ({ children }: NewNavigationProps): JSX.Element => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { pathname } = useLocation()

  const { initializeAppCalls } = useMainNavigation()

  const [onboardingInviteModalOpen, setOnboardingInviteModalOpen] = useState(false)
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false)
  const [sideBarCollapsed, setSideBarCollapsed] = useState(false)
  const [collapseDisabled, setCollapseDisabled] = useState(false)
  const { toggleAnnouncementDrawer } = useOrgManagement((s) => s.announcementsDrawer)
  const { employee, step_tour, company, isJisriChatbotEnabled } = useSelector(({ auth }) => auth)
  const switchableAccounts = useSelector((s) => s.switchableOrganizations.accounts)
  const onboardingSteps = useSelector((s) => s.onboardingHeaderSteps)
  const isUnread = useSelector(({ markAsRead }) => markAsRead.unread)
  const { data: isAnnouncementUnread } = useAnnouncementUnreadExists({
    query: {
      select: (s) => s.data.data?.unread_exists,
      enabled: company?.subscribed_features?.includes('employee_engagement'),
    },
  })
  const is_jisr_pay_enabled = useSelector((s) => s.auth.employee?.organization.jisrpay_enabled)
  const isFlightBookingEnabled = useSelector(
    (s) =>
      s.auth.employee?.organization?.benefits_management_enablement?.sub_modules_enablement
        ?.flight_ticket_bookings_enabled,
  )
  const activatedModulesCount = company?.activated_modules?.length ?? 0
  const isNewOnboardingEnabled = employee?.organization?.new_onboarding_enabled
  const isOnboardingPhase = employee?.organization?.status === Statuses.ONBOARDING_PHASE
  const isNewOnboarding = isNewOnboardingEnabled && isOnboardingPhase
  const isHybridPhase = !isNewOnboarding && isNewOnboardingEnabled && activatedModulesCount < 3
  const showOnboardingHeader = isNewOnboarding || isHybridPhase

  const mainMapNavigation = useMappingNavigation()
  const disabledNavItems = useDisabledMappingNavigation()
  const onboardingNavItems = useOnlyOnboardingMappingNavigation()
  const handleMapNavItems = isNewOnboarding ? onboardingNavItems : mainMapNavigation
  const list = step_tour === 3 ? disabledNavItems : handleMapNavItems

  const canPostAnnouncement = employee?.permission_scopes?.post_announcements
  const canMakePayments = employee?.permission_details?.make_payments
  const canManageFlightBookings = employee?.permission_scopes?.manage_flight_bookings
  const showAccountSwitcher = switchableAccounts.length > 1
  const isCurrentEmpOrg = useIsEmployeeOrg()
  const userSnapApi = useUsersnapApi()
  const listItems = useListItems()
  const isHomePage = pathname.split('/')[1] === ''

  useEffect(() => {
    if (isJisriChatbotEnabled) {
      try {
        const domainSplit = window.location.hostname.split('.')
        const domain = domainSplit.splice(-2, domainSplit.length).join('.')
        const secure = window.location.protocol === 'https:' ? 'Secure;' : ''
        document.cookie = `slug=${getOrganisationName()}; path=/; domain=.${domain}; ${secure} SameSite=Strict;`
      } catch (e) {
        // ERROR in setting cookie
      }
    }
  }, [isJisriChatbotEnabled])

  useEffect(() => {
    if (isJisriChatbotEnabled === null) {
      dispatch(getCurrentUser())
    }
  }, [dispatch, isJisriChatbotEnabled])

  const toggleSidebar = (): void => {
    sessionStorage.setItem('collaps', `${!sideBarCollapsed}`)
    setSideBarCollapsed(!sideBarCollapsed)
  }

  const handleLogout = async (): Promise<void> => {
    const unsubscribe = new Promise(() =>
      getTokenFromDB({ clear: true }).then((token) => {
        if (token) return dispatch(unsubscribeFirebase({ token }))
        return null
      }),
    )
    const Unregister = await getTokenFromDB().then((token) => {
      if (token) return dispatch(unRegistrationToken(token, 'firebase'))
      return null
    })

    if (import.meta.env.UkVBQ1RfQVBQX0ZFX0NBTkRVX1RPS0VO && window.Candu) {
      window.Candu.tearDown()
    }

    localStorage.removeItem(CHATBOT_SAVED_MESSAGES)
    localStorage.removeItem(CHATBOT_START_TIMESTAMP)
    localStorage.removeItem(JISRI_SELECTED_LANGUAGE)

    const logoutSession = new Promise(() => dispatch(logout()))

    Promise.all([unsubscribe, Unregister, logoutSession]).finally(() => history.push('/'))
    queryClient.clear()
  }

  const handleHeaderNavigation = (
    e: React.MouseEvent<HTMLLIElement, MouseEvent>,
    data: { value: string },
  ): void => {
    const actions: Record<string, () => void> = {
      ...(isCurrentEmpOrg && { view_profile: () => history.push(`/employees/${employee?.id}`) }),
      view_profile: () => history.push(`/employees/${employee?.id}`),
      change_password: () => setIsPasswordModalOpen(true),
      language: (): void => {
        dispatch(
          currentEmployeeChangeLanguage(employee?.id as number, {
            value: I18n.language === 'ar' ? 'en' : 'ar',
          }),
        )
      },
      logout: () => handleLogout(),
    }
    const actionHandler = actions[data.value]
    if (actionHandler) {
      actionHandler()
    }
  }

  const handleCollapse = (): void => {
    sessionStorage.setItem('collaps', `${false}`)
    setSideBarCollapsed(false)
    if (sideBarCollapsed && collapseDisabled) {
      setCollapseDisabled(false)
    }
  }

  useEffect(() => {
    const path = pathname.split('/')[1]
    const currentPath = Object.values(list).find((val) => val?.tab?.includes(path))
    if (isEmpty(currentPath?.menu)) {
      setCollapseDisabled(true)
      setSideBarCollapsed(true)
    } else {
      setCollapseDisabled(false)
      setSideBarCollapsed(sessionStorage.getItem('collaps') === 'true')
    }
  }, [pathname, employee?.id, list])

  useEffect(() => {
    initializeAppCalls()
    if (
      employee?.is_super_admin &&
      company?.subscribed_features?.includes('performance_management')
    ) {
      userSnapApi?.logEvent('performance_users')
    }
  }, [])

  return (
    <>
      <PageLayout
        collapsed={sideBarCollapsed}
        className={clsx(onboardingSteps.title && styles.newOnboardingPageLayout)}
        sidePanel={
          <SideBarNav
            collapsed={sideBarCollapsed}
            logo={
              <div style={{ position: 'relative' }}>
                <img
                  src={getOrganisationName() === 'my' ? SMELogo : JisrNavLogo}
                  alt="log"
                />
                <TourStepThree />
              </div>
            }
            menuData={list}
            defaultNav={!isNewOnboarding ? 'general' : 'onboarding'}
            bottomNav={
              ['settings', (isNewOnboarding || isHybridPhase) && 'onboarding'].filter(
                Boolean,
              ) as string[]
            }
            activePath={pathname}
            onMenuClick={(nav: Level1NavItem | undefined): void => {
              if (isEmpty(nav?.menu)) {
                setSideBarCollapsed(true)
                if (nav?.path) {
                  history.push(nav.path)
                }
                if (nav?.event_name) mixPanelAnalytics({ event_name: nav.event_name })
                setCollapseDisabled(true)
              } else {
                handleCollapse()
              }
            }}
            onSubMenuClick={(item: Level2NavItem | undefined): void => {
              if (item) {
                history.push(item.path)
                if (item?.event_name) mixPanelAnalytics({ event_name: item.event_name })
              }
            }}
            {...(showAccountSwitcher && { switcherDropdown: <AccountSwitcher /> })}
          />
        }
        navigationHeader={
          <HeaderNavigation
            showToggelButton={!showOnboardingHeader}
            showUserProfileCard={!showOnboardingHeader}
            employee={{
              name: employee?.full_name_i18n ?? '',
              avatar: employee?.avatar_thumb ?? '',
              email: employee?.email ?? '',
            }}
            showAvatarCompanyIcon={false}
            notificationButtonClick={(): void => {
              dispatch(toggleNotificationsPanel(true))
            }}
            onClickListItem={handleHeaderNavigation}
            dropdownListItems={listItems}
            toggleSidebar={toggleSidebar}
            collapsed={sideBarCollapsed}
            collapseDisabled={collapseDisabled}
            headerCheldren={
              !showOnboardingHeader && (
                <>
                  {canPostAnnouncement &&
                    company?.subscribed_features?.includes('employee_engagement') && (
                      <Announcement />
                    )}
                  {company?.subscribed_features?.includes('employee_engagement') && (
                    <div className="relative">
                      <Button
                        iconOnly
                        size="medium"
                        variant="ghost"
                        color="neutral"
                        leadingIcon="announcement-02"
                        onClick={(): void => {
                          toggleAnnouncementDrawer(true)
                        }}
                      />
                      {isAnnouncementUnread && (
                        <span className="w-2 h-2 rounded-full bg-base-colors-red-600 absolute top-[10px] end-3" />
                      )}
                    </div>
                  )}

                  <div className="relative">
                    <Button
                      iconOnly
                      size="medium"
                      variant="ghost"
                      color="neutral"
                      leadingIcon="bell-02"
                      onClick={(): void => {
                        dispatch(toggleNotificationsPanel(true))
                      }}
                    />
                    {isUnread && (
                      <span className="w-2 h-2 rounded-full bg-base-colors-red-600 absolute top-[10px] end-3" />
                    )}
                  </div>

                  <HelpCenter />
                  {(isFlightBookingEnabled || is_jisr_pay_enabled) &&
                    (canManageFlightBookings || canMakePayments) && <JisrPayWallet />}
                </>
              )
            }
            {...(showOnboardingHeader && {
              className: styles.newOnboardingHeader,
              headerCheldrenStartSide: (
                <OnboardingHeader
                  onClickListItem={handleHeaderNavigation}
                  setOnboardingInviteModalOpen={setOnboardingInviteModalOpen}
                />
              ),
            })}
          />
        }
        body={
          <>
            {children}
            <UserSnap />
          </>
        }
      />
      <ChangePasswordModal
        isOpen={isPasswordModalOpen}
        onClose={(): void => setIsPasswordModalOpen(false)}
      />
      {(isNewOnboarding || isHybridPhase) && (
        <OnboardingInviteAdmins
          isOpen={onboardingInviteModalOpen}
          onClose={(): void => setOnboardingInviteModalOpen(false)}
        />
      )}
      {isJisriChatbotEnabled && CHATBOT_URL && isHomePage && <JisriChatbotLauncher />}
    </>
  )
}

export default NewNavigation

import { handleResponseErr } from 'utils/apiHelperUtils'
import { Toastr } from 'components/global/Toastr'
import { Action } from 'types/redux'
import { reactQueryChangeSlug } from 'apis/helper'
import { getOrganisationName } from 'env'
import * as actions from './actions'
import { setAuthToken, removeAuthToken } from './actionCreators'
import { fixLocale, handlePerformancePermisstion, handlePermissions } from './helper'
import { AuthState, Employee, NavigationBucket } from './types'

const DEFAULT_STATE: AuthState = {
  errMsg: null,
  succMsg: null,
  fetching: false,
  employee: null,
  employeeDate: new Date(),
  company: null,
  email: null,
  fetching_change_language: false,
  last_payrun: {
    payrun: {},
  },
  performance_permission: {},
  policies: {
    attempts: null,
    is_google_captcha_enabled: false,
    login_attempt_applicable: false,
  },
  slugs: [],
  is_saml_account_enabled: false,
  welcome_page_tour_completed: false,
  department_tour_completed: false,
  isJisriChatbotEnabled: null,
  redirectAfterLogin: null,
}

export default function reducer(state = DEFAULT_STATE, action: Action): AuthState {
  const challangeWithCaptcha =
    action.type === actions.LOGIN_REJECTED && action.payload?.response?.status === 409
  switch (action.type) {
    case actions.LAST_CONFIRMED_PAYROLL_PENDING:
    case actions.CHANGE_PASSWORD_PENDING:
    case actions.FORGOT_PASSWORD_PENDING:
    case actions.GET_CURRENT_USER_PENDING:
    case actions.LOGIN_PENDING:
    case actions.LOGOUT_PENDING:
    case actions.RESET_PASSWORD_PENDING:
    case actions.VALIDATE_COMPANY_KSA_PENDING:
    case actions.COMPANY_SUBDOMAINS_PENDING:
    case actions.MICROSOFT_VERIFY_OTP_PENDING:
    case actions.VERIFY_PHONE_NUMBER_PENDING:
    case actions.VERIFY_OTP_DIGITS_PENDING:
      return {
        ...state,
        fetching: true,
        errMsg: null,
        succMsg: null,
      }

    case actions.CURRENT_EMPLOYEE_CHANGE_LANGUAGE_PENDING:
      return {
        ...state,
        fetching_change_language: true,
      }

    case actions.CHANGE_PASSWORD_REJECTED:
    case actions.FORGOT_PASSWORD_REJECTED:
    case actions.RESET_PASSWORD_REJECTED:
    case actions.EXPERIMENT_VALUES_UPDATE_REJECTED:
    case actions.EXPERIMENT_VALUES_CREATE_REJECTED:
    case actions.COMPANY_SUBDOMAINS_REJECTED:
    case actions.MICROSOFT_VERIFY_OTP_REJECTED:
    case actions.VERIFY_PHONE_NUMBER_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }

    case actions.GET_CURRENT_USER_REJECTED:
    case actions.LAST_CONFIRMED_PAYROLL_REJECTED:
    case actions.VERIFY_OTP_DIGITS_REJECTED:
      Toastr.error(handleResponseErr(action.payload))
      return {
        ...state,
        fetching: false,
      }

    case actions.CURRENT_EMPLOYEE_CHANGE_LANGUAGE_FULFILLED:
      return {
        ...state,
        ...action.payload?.data?.data,
        fetching_change_language: false,
      }

    case actions.CURRENT_EMPLOYEE_CHANGE_LANGUAGE_REJECTED:
      return {
        ...state,
        fetching_change_language: false,
      }

    case actions.LOGIN_REJECTED:
    case actions.LOGOUT_REJECTED:
    case actions.VALIDATE_COMPANY_KSA_REJECTED:
      const askForChangePassword = action.payload?.response?.data?.required_change_password

      removeAuthToken()
      if (!askForChangePassword && !challangeWithCaptcha) {
        Toastr.error(handleResponseErr(action.payload))
      }
      return {
        ...state,
        policies: action.payload?.response?.data?.policies,
        errMsg: handleResponseErr(action.payload),
        fetching: false,
      }

    case actions.CHANGE_PASSWORD_FULFILLED:
      Toastr.success(action.payload.data?.message)
      return {
        ...state,
        fetching: false,
      }

    case actions.COMPANY_SUBDOMAINS_FULFILLED:
      if (action.payload.data?.message) {
        Toastr.success(action.payload.data?.message)
      }
      return {
        ...state,
        ...action.payload.data.data,
        fetching: false,
      }

    case actions.LOGIN_FULFILLED:
    case actions.MICROSOFT_VERIFY_OTP_FULFILLED:
    case actions.VERIFY_OTP_DIGITS_FULFILLED:
      const { employee: loginEmployee } = action.payload.data?.data
      const slug = getOrganisationName()

      if (action.payload.data.data.suspended_token) {
        Toastr.error(action.payload.data.message)
      }
      if (action.payload.data.message && !action.payload.data.data.suspended_token) {
        Toastr.success(action.payload.data.message)
      }

      if (action.payload.status === 200) {
        setAuthToken()
      }

      if (slug) {
        reactQueryChangeSlug(slug)
      }

      fixLocale(loginEmployee)

      return {
        ...state,
        ...action.payload.data.data,
        ...(loginEmployee &&
          state.company && {
            employee: {
              ...loginEmployee,
              permissions: handlePermissions(loginEmployee, state.company),
            },
            ...(typeof loginEmployee?.completed_tour === 'object' && {
              step_tour: loginEmployee.completed_tour.welcome_page_tour_completed ? null : 1,
              welcome_page_tour_completed: loginEmployee.completed_tour.welcome_page_tour_completed,
              department_tour_completed: loginEmployee.completed_tour.department_tour_completed,
            }),
          }),
        succMsg: action.payload.data.message,
        errMsg: null,
        fetching: false,
        policies: null,
      }

    case actions.CHANGES_ORGNIATION_STATUS: {
      const status = action.payload as 'new_account' | 'onboarding_phase' | 'live'
      const employee = (state.employee || {}) as Employee
      return {
        ...state,
        employee: {
          ...employee,
          organization: {
            ...employee?.organization,
            status,
          },
        },
      }
    }

    case actions.ONBOARDING_CHANGES_ORGANIZATION_INFO: {
      const employee: Employee = (state.employee || {}) as Employee

      return {
        ...state,
        employee: {
          ...employee,
          organization: {
            ...employee?.organization,
            ...action.payload,
          },
        },
      }
    }

    case actions.GET_CURRENT_USER_FULFILLED: {
      const employee = action?.payload?.data?.data?.employee ?? {}
      const role_type = employee?.permission_scopes?.manage_billing?.some(
        (it: string) => it === 'all',
      )
        ? 'admin'
        : 'other'
      const role = { role_type, is_super_admin: role_type === 'admin' }
      const isJisriChatbotEnabled = action?.payload?.data?.data?.chatbot_enabled ?? false

      fixLocale(employee)

      return {
        ...state,
        ...action.payload.data.data,
        employee: state.company && {
          ...employee,
          role,
          permissions: handlePermissions(employee, state.company),
        },

        employeeDate:
          action.payload.headers && action.payload.headers.date
            ? new Date(action.payload.headers.date)
            : state.employeeDate,
        performance_permission: handlePerformancePermisstion(
          employee.permission_scopes,
          employee.is_super_admin,
        ),
        succMsg: action.payload.data.message,
        ...(typeof employee?.completed_tour === 'object' && {
          step_tour: employee.completed_tour.welcome_page_tour_completed ? null : 1,
          welcome_page_tour_completed: employee.completed_tour.welcome_page_tour_completed,
          department_tour_completed: employee.completed_tour.department_tour_completed,
        }),
        errMsg: null,
        fetching: false,
        isJisriChatbotEnabled,
      }
    }

    case actions.ONBOARDING_CHANGE_TOUR_STEP:
      return {
        ...state,
        ...action.payload,
      }

    case actions.RESET_PASSWORD_FULFILLED:
      Toastr.success(action.payload.data?.message)
      return {
        ...state,
        ...action.payload.data.data,
        succMsg: action.payload.data?.message,
        errMsg: null,
        fetching: false,
      }

    case actions.FORGOT_PASSWORD_FULFILLED:
      return {
        ...state,
        ...action.payload.data.data,
        succMsg: action.payload.data?.message,
        email: action.payload.data.data?.email,
        errMsg: null,
        fetching: false,
      }

    case actions.LOGOUT_FULFILLED:
      Toastr.success(action.payload.data.message)
      removeAuthToken()
      return {
        ...state,
        employee: null,
        succMsg: action.payload.data?.message,
        errMsg: null,
        fetching: false,
      }

    case actions.LAST_CONFIRMED_PAYROLL_FULFILLED: {
      if (!action.payload.data?.data) {
        return {
          ...state,
          fetching: false,
        }
      }
      return {
        ...state,
        last_payrun: action.payload.data?.data,
        fetching: false,
      }
    }

    case actions.VALIDATE_COMPANY_FULFILLED:
    case actions.VALIDATE_COMPANY_KSA_FULFILLED:
      return {
        ...state,
        company: action.payload.data?.data,
        succMsg: action.payload?.data?.message,
        subscribedFeatures: action.payload.data?.data?.subscribed_features,
        is_saml_account_enabled: action.payload.data?.data?.is_saml_account_enabled,
        errMsg: null,
        fetching: false,
      }

    case actions.REMOVE_AUTH_USER:
      return {
        ...state,
        employee: null,
        errMsg: null,
        fetching: false,
      }

    case actions.UPDATE_EMPLOYEE_PERMISSION:
      const newEmployeeDetail = {
        ...state.employee,
        ...action.payload,
        organization: {
          ...action.payload.organization,
          status: state.employee?.organization.status,
        },
      }

      return {
        ...state,
        ...(state.employee &&
          state.company && {
            employee: {
              ...newEmployeeDetail,
              permissions: handlePermissions(newEmployeeDetail, state.company),
            },
          }),
      }

    case actions.EXPERIMENT_VALUES_CREATE_FULFILLED:
    case actions.EXPERIMENT_VALUES_UPDATE_FULFILLED:
      const navigation_bucket: NavigationBucket = action.payload.data?.data?.experiment_value

      return {
        ...state,
        employee: state.employee
          ? {
              ...state.employee,
              navigation_bucket,
            }
          : state.employee,
        fetching: false,
      }

    case actions.VERIFY_PHONE_NUMBER_FULFILLED:
      return {
        ...state,
        fetching: false,
      }

    case actions.REDIRECT_AFTER_LOGIN:
      return {
        ...state,
        redirectAfterLogin: action.payload,
      }

    default:
      return state
  }
}

import axios, { AxiosPromise, Method } from 'axios'
import { getOrganisationName, PERFORMANCE_ROOT } from 'env'
import Qs, { IStringifyOptions } from 'qs'
import { SimpleObject } from 'types/sharedComponents'
import { serializerConfig } from './apiUtils'
import errorResponseHandler from './errorHandler'

const instance = axios.create({
  withCredentials: true,
  baseURL: PERFORMANCE_ROOT,
})

instance.interceptors.request.use((config) => {
  config.params = {
    ...config.params,
    slug: getOrganisationName(),
  }
  return config
})

if (instance.interceptors) {
  instance.interceptors.response.use(undefined, errorResponseHandler)
}

function performanceAPI<
  P extends SimpleObject = SimpleObject,
  D extends SimpleObject = SimpleObject,
>(url: string, params: P | SimpleObject = {}, method: Method, data?: D | null): AxiosPromise {
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    locale: localStorage.getItem('Locale') ?? 'en',
    slug: getOrganisationName(),
  }

  return instance({
    method,
    url,
    params,
    paramsSerializer: (paramObject) =>
      Qs.stringify(paramObject, serializerConfig as Partial<IStringifyOptions>),
    data,
    headers,
  })
}

export { performanceAPI }

import axios, { AxiosPromise } from 'axios'
import { ASSET_MANAGER_ROOT, getOrganisationName } from 'env'
import errorResponseHandler from './errorHandler'

const instance = axios.create({
  withCredentials: true,
})

instance.interceptors.request.use((config) => {
  config.params = {
    ...config.params,
    slug: getOrganisationName(),
  }
  return config
})

if (instance.interceptors) {
  instance.interceptors.response.use(undefined, errorResponseHandler)
}
type MethodType = 'POST' | 'DELETE' | 'PUT' | 'GET'

type Headers = {
  Accept?: string
  'Access-Token'?: string | null
  'Content-Type': string
  locale?: string
  pincode?: string | null
  slug?: string | null
}

function assetmanagerAPI(
  url: string,
  params: Record<string, unknown>,
  method: MethodType,
  data: unknown = null,
  baseURL?: string,
): AxiosPromise | Record<string, never> {
  const isFormData = data instanceof FormData
  const headers: Headers = {
    'Content-Type': isFormData ? 'multipart/form-data' : 'application/json',
    Accept: 'application/json',
    ...(localStorage.getItem('onboarding_token') && {
      'Access-Token': localStorage.getItem('onboarding_token'),
    }),
    locale: localStorage.getItem('Locale') ?? 'en',
    slug: getOrganisationName(),
    pincode: btoa(sessionStorage.getItem(atob('pincode')) || '') || null,
  }

  if (!headers.pincode) {
    delete headers.pincode
  }

  const urlPath = baseURL ? baseURL + url : ASSET_MANAGER_ROOT + url

  return instance({
    method,
    url: urlPath,
    params,
    data,
    headers,
  })
}

export { assetmanagerAPI }

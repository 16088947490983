import { FC, useEffect, useMemo } from 'react'
import Intercom from 'components/global/Intercom'
import { useSelector } from 'utils/hooks'
import { getOrganisationName } from 'env'
import {
  getCurrentUser,
  removeAuthToken,
  setAuthToken,
  validateCompany,
} from 'redux/authUser/actionCreators'
import { useDispatch, shallowEqual } from 'react-redux'
import AppNotifications from 'containers/authorised/AppNotifications'
import { Statuses } from 'containers/authorised/NewOnboarding/helper'
import useRouteQueries from 'components/global/hooks/useRouteQueries'
import NavigationHandler from 'components/NavigationHandler'
import AnnouncementsDrawer from 'containers/authorised/Overview/components/announcements/components/announcementsDrawer'

// Routes
import PublicRoutes from './public/authRoutes'

const RootRouter: FC = () => {
  const dispatch = useDispatch()
  const currentUser = useSelector((s) => s.auth.employee)
  const { loginFromOnboarding } = useSelector((s) => s.organization, shallowEqual)
  const isOnboardingPhase = // used for onboarding organizations
    currentUser?.organization?.new_onboarding_enabled &&
    currentUser?.organization.status === Statuses.ONBOARDING_PHASE

  const isOnboardingEmp = useSelector((state) => state.auth?.employee?.is_onboarding_employee) // used for onboarding employees

  const domainSplit = window.location.hostname.split('.')
  const domain = domainSplit.splice(1, domainSplit.length).join('.')

  const LoggedIn = useMemo(
    () =>
      document.cookie
        .split('; ')
        .find((row) => row.startsWith('LoggedIn='))
        ?.split('=')[1] || '',
    [],
  )
  if (LoggedIn && !window.location.hostname.includes('signin.')) {
    setAuthToken()
    document.cookie = `LoggedIn=; path=/; domain=.${domain}; ${document.cookie}`
  }

  const { switchedAccount } = useRouteQueries(true)

  const isNotCareersPage = !window.location.hash?.includes('#/careers')
  const isLogged = localStorage.getItem('LoggedIn')

  const validatorCall = async (): Promise<void> => {
    await dispatch(validateCompany({ company_name: getOrganisationName() || '' }))
    dispatch(getCurrentUser())
  }

  const shouldShowNavigation = (loginFromOnboarding || isLogged) && isNotCareersPage

  useEffect(() => {
    if (switchedAccount) {
      let url = window.location.href
      if (!isLogged) {
        setAuthToken()
        window.location.reload()
      } else {
        url = url.replace('?switchedAccount=true', '')
        window.location.href = url
      }
    }
  }, [switchedAccount])

  useEffect(() => {
    if ((loginFromOnboarding || isLogged) && isNotCareersPage) validatorCall()
  }, [loginFromOnboarding])

  useEffect(() => {
    if (window.location.hostname.includes('signin.')) removeAuthToken()
  }, [])

  if (!shouldShowNavigation) {
    return <PublicRoutes />
  }

  return (
    <>
      <NavigationHandler isOnboardingEmp={isOnboardingEmp} />
      <Intercom />
      {currentUser !== null && !isOnboardingPhase && !isOnboardingEmp && (
        <>
          <AppNotifications /> <AnnouncementsDrawer />
        </>
      )}
    </>
  )
}

export default RootRouter

import { getOrganisationName } from 'env'
import { api } from './axiosInstance'
import { ATSApi } from './ATSAxiosInstance'
import { PerformanceApi } from './pmAxiosInstance'
import { assetManagerAxiosInstance } from './assetManagerAxiosInstance'

export const reactQueryChangeSlug = (slug?: string) => {
  const headerSlug = slug || getOrganisationName()
  api.defaults.headers.slug = headerSlug
  ATSApi.defaults.headers.slug = headerSlug
  PerformanceApi.defaults.headers.slug = headerSlug
  assetManagerAxiosInstance.defaults.headers.slug = headerSlug
}
